<template>
  <b-modal
    id="fileDetails"
    title="File Details"
    @show="loadDetails()"
    @hidden="reset()"
    ok-only
    size="lg"
  >
    <div class="container">
      <div class="row mb-3">
        <info-line heading="h6" label="Name" class="col">
          <a :href="getUrl(file.id)" :download="file.name">
            {{ file.name }}
          </a>
        </info-line>
        <info-line
          heading="h6"
          label="Size"
          :value="formatBytes(file.size)"
          class="col"
        />
      </div>
      <info-line
        heading="h6"
        label="Description"
        :value="file.description"
        class="mb-3"
      />
      <div class="row mb-3">
        <info-line
          heading="h6"
          label="Type"
          :value="mimeTypeFormatter(file.mimeType)"
          class="col"
        />
        <info-line
          heading="h6"
          label="Expiry date"
          :value="formatDate(file.expiryDate)"
          class="col"
        />
      </div>
      <div class="row mb-3">
        <info-line
          heading="h6"
          label="Created by"
          :value="file.createdBy"
          class="col"
        />
        <info-line
          heading="h6"
          label="Created on"
          :value="formatDate(file.createdOn)"
          class="col"
        />
      </div>
      <div class="row">
        <info-line
          heading="h6"
          label="Last updated by"
          :value="file.lastUpdatedBy"
          class="col"
        />
        <info-line
          heading="h6"
          label="Last updated on"
          :value="formatDate(file.lastUpdatedOn)"
          class="col"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import InfoLine from "@/components/interface/InfoLine.vue";
import moment from "moment";
import numeral from "numeral";

export default {
  name: "FileDetailsModal",
  components: {InfoLine},
  props: ["fileId"],
  data() {
    return {
      file: {
        name: "",
        size: "",
        mimeType: "",
        description: "",
        expiryDate: "",
        createdBy: "",
        createdOn: "",
        lastUpdatedBy: "",
        lastUpdatedOn: "",
      },
    };
  },
  methods: {
    ...mapGetters("fileStore", ["getMimeTypeByName"]),
    ...mapActions("fileStore", ["fetchFileDetails"]),
    loadDetails() {
      this.fetchFileDetails(this.fileId).then(file => {
        this.file = {...file};
      });
    },
    reset() {
      this.file = {};
    },
    close() {
      this.$bvModal.hide("fileDetails");
    },
    formatDate(date) {
      if (!date) {
        return "No expiry";
      }
      return moment(date, moment.ISO_8601).format("DD/MM/YYYY");
    },
    formatBytes(size) {
      return numeral(size).format("0.0 b");
    },
    mimeTypeFormatter(mimeType) {
      const foundType = this.getMimeTypeByName()(mimeType);
      if (foundType) {
        return foundType.shortName;
      } else {
        return "Unknown";
      }
    },
    getUrl(fileId) {
      return "/api/v1/files/" + fileId + "/file";
    },
  },
};
</script>
